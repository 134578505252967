<template>
  <div class="container">
    <h3 class="mt-3 mb-3 text-center">Список отчетов по аналитике</h3>
    <div v-if="decryptCheckRole('admin')" class="mt-3 mb-5">
      <Button class="me-1" @click="modal_create=true" label="Создать ссылку"></Button>
      <Button @click="modal_delete=true" label="Удалить ссылку"></Button>
    </div>

    <p v-for="(analytic, index) in AnalyticsData" :key="index"><a target="_blank" :href="analytic.url">{{analytic.name}}</a></p>
  </div>

  <Dialog v-model:visible="modal_create" :style="{ width: '50vw' }" modal header="Создать ссылку">
    <div class="col-md-12 mt-4">
      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Название</label>
        <div class="col-md-9">
          <InputText v-model="output_data.name" class="form-control"></InputText>
        </div>
      </div>
      <div class="form-group row mt-4">
        <label class="col-md-3 col-form-label">Ссылка</label>
        <div class="col-md-9">
          <InputText v-model="output_data.url" class="form-control"></InputText>
        </div>
      </div>
    </div>
    <template #footer>
      <Button label="Сохранить" @click="submitCreate"></Button>
    </template>
  </Dialog>

  <Dialog v-model:visible="modal_delete" :style="{ width: '50vw' }" modal header="Удалить ссылку">
    <div class="form-group row mt-4">
      <label class="col-md-3 col-form-label">Выбрать</label>
      <div class="col-md-9">
        <Dropdown :options="AnalyticsData" v-model="output_delete_id" optionLabel="name" optionValue="id"
                  class="form-control"></Dropdown>
      </div>
    </div>

    <template #footer>
      <Button label="Сохранить" @click="submitDelete"></Button>
    </template>
  </Dialog>
</template>

<script>
import httpClient from "../../services/http.service";
import {mapActions, mapState} from "vuex";
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
export default {
  name: "ShowAnalysis",
  data() {
    return {
      modal_create: false,
      modal_delete: false,
      output_data: {
        name: null,
        url: null
      },
      output_delete_id: null
    }
  },
  computed: {
    ...mapState('analytics', ['AnalyticsData']),
  },
  methods: {
    decryptCheckRole,
    ...mapActions('analytics', ['GET_ANALYTICS', 'CREATE_ANALYTICS', 'DELETE_ANALYTICS']),

    async submitCreate() {
      if (await this.CREATE_ANALYTICS(this.output_data)) {
        await this.GET_ANALYTICS()
        this.$message({title: "Данные успешно сохранились!"})
      }
      this.modal_create = false
    },

    async submitDelete() {
      if (await this.DELETE_ANALYTICS(this.output_delete_id)) {
        await this.GET_ANALYTICS()
        this.$message({title: "Данные успешно удалились!"})
      }
      this.modal_delete = false
    }
  },
  async mounted() {
    await this.GET_ANALYTICS()
  }
}
</script>

<style scoped>
</style>
